<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
      :eager="true"
    >
      <v-form ref="form" v-model="valid">
        <v-card max-height="100vh">

          <div>
            <v-system-bar dark color="primary">
              <v-icon class="mr-1">mdi-pencil</v-icon>
              <small><strong class="text-uppercase">{{ $t('COMMON.MODIFY') }}</strong></small>
              <v-spacer/>
              <v-icon
                small
                class="clickable"
                @click="closeDialog"
              >
                mdi-close
              </v-icon>
            </v-system-bar>

            <v-toolbar
              color="transparent"
              flat
              dense
              height="60px"
            >
              <v-toolbar-title>{{ $t('JOB.CUSTOM_PARAMS') }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                x-small
                fab
                depressed
                color="error"
                class="mr-4"
                @click="closeDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                rounded
                depressed
                color="success"
                :disabled="!valid"
                @click="apply"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                {{ $t('COMMON.APPLY') }}
              </v-btn>
            </v-toolbar>

            <div class="toolbar-separator primary ml-4 mr-4"/>
          </div>

          <div class="pa-6 pt-0 scroll" style="max-height: calc(80vh - 61px)" :key="updateKey">

            <p class="mt-4">
              <v-btn depressed rounded small class="secondary black--text" @click="addCustomField">
                <v-icon class="mr-2">mdi-plus</v-icon>
                <span>{{ $t('COMMON.ADD') }}</span>
              </v-btn>
            </p>

            <v-row :key="'cuf'+i" class="mt-0 mb-4" v-for="(key, i) in customKeys">
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="customKeys[i]"
                  @change="changeCustomField(i, $event)"
                  :rule="[$rules.required]"
                  :label="$t('COMMON.KEY')"
                  :placeholder="$t('COMMON.KEY')"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="6" class="pt-0 pb-0 pl-0">
                <v-text-field
                  dense
                  v-model="custom[key]"
                  :label="$t('COMMON.VALUE')"
                  :placeholder="$t('COMMON.VALUE')"
                  outlined
                  hide-details
                />
              </v-col>
              <v-spacer />
              <v-col cols="1" class="pa-0">
                <v-btn icon @click="removeCustomField(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>

          </div>
        </v-card>
      </v-form>
    </v-dialog>

    <div>
      <span class="primary--text">{{ $t('JOB.CUSTOM_PARAMS') }}</span>
      <v-btn
        small
        rounded
        depressed
        color="secondary black--text"
        class="ml-4 mr-4"
        @click="showDialog"
      >
        {{ $t('COMMON.MODIFY') }}
      </v-btn>
    </div>

    <div class="mt-4">

      <div :key="'cu'+i" v-for="(key, i) in Object.keys(values.custom)">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="key">
            {{ key }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.custom[key] }}</span>
          </v-col>
        </v-row>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name:"FormJobCompactCustom",
  components: {},
  data: (_this) => ({
    valid: false,
    dialog: false,
    updateKey:0,
    customKeys: [],
    oldCustomKeys: [],
    //
    custom: {}
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  computed: {
    filledFieldsCount () {
      return +!!this.protocol
    },
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.attribValues()
  },
  methods: {

    removeCustomField (i) {
      const key = this.customKeys[i]
      this.customKeys.splice(i,1)
      this.oldCustomKeys.splice(i,1)
      // delete field
      const custom = this.$clone(this.custom)
      custom[key] = undefined
      this.custom = custom
      this.updateKey++ // force rerender
    },

    addCustomField () {
      const key = this.$i18n.t('COMMON.KEY') + '_' + (this.customKeys.length+1)
      this.customKeys.push(key)
      this.custom[key] = ''
      this.updateKey++ // force rerender
    },

    changeCustomField (i, key) {
      key = key || ('' + (this.customKeys.length+1))
      const oldKey = this.oldCustomKeys[i]
      const currentValue = this.custom[oldKey]
      this.custom[key] = currentValue || ''
      this.customKeys[i] = key
      // delete old field
      const custom = this.$clone(this.custom)
      delete custom[oldKey]
      this.oldCustomKeys = this.$clone(this.customKeys)
      this.custom = custom
      this.updateKey++ // force rerender
    },

    // form fields
    apply () {
      if (this.$refs.form.validate()) {
        this.$emit('input', {custom: {...this.custom}})
        this.dialog = false
      }
    },
    closeDialog () {
      this.dialog = false
    },
    showDialog () {
      this.fileformat_fields_form = this.$clone(this.fileformat_fields)
      this.dialog = true
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'custom') {
          this.customKeys = Object.keys(values[key])
        }
        this[key] = values[key]
      })
    }
  }
}
</script>
