<template>

    <div class="form-compact form-compact-job">

      <v-dialog v-model="dialogParsing" width="700px">
        <Tree :value="values.parsing_result" />
      </v-dialog>


      <div class="form-compact-header">
        <v-system-bar dark color="primary">
          <v-icon class="mr-1">{{ $t('JOB.ICON') }}</v-icon>
          <template v-if="id">
            <small><strong class="text-uppercase">{{ $t('JOB.FORM_TITLE_UPDATE') }}</strong></small>
            <v-icon class="ml-4" size="12">mdi-pound</v-icon>
            <small>{{ loaded_values.id }}</small>
          </template>
          <small v-else><strong class="text-uppercase">{{ $t('JOB.FORM_TITLE_CREATE') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-toolbar
          color="transparent"
          flat
          height="66px"
        >
          <v-toolbar-title class="primary--text">
            {{ this.id ? this.loaded_values.title : $t('JOB.FORM_TITLE_NEW') }}
          </v-toolbar-title>
          <v-spacer />
          <div class="mr-6" >
            <StatusLabel :item="loaded_values" />
          </div>
          <div class="mr-6 text-nowrap" v-if="ids.length > 1">
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  depressed
                  color="grey"
                  :disabled="!canPrev"
                  @click="prevItem"
                  class="mr-2"
                >
                  <v-icon color="white">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.PREVIOUS') }}
            </v-tooltip>

            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  depressed
                  color="grey"
                  :disabled="!canNext"
                  @click="nextItem"
                >
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.NEXT') }}
            </v-tooltip>
          </div>
          <div class="mr-6 d-flex text-nowrap" v-if="id">
            <div>
              <v-tooltip bottom transition="none" v-if="values.parent_id">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="mr-2"
                    @click="separateJobFeed"
                  >
                    <v-icon>mdi-rss-off</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.SEPARATE_JOB_FEED') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template
                  v-if="loaded_values.id && loaded_values.parent_id !== null"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="blue darken-4"
                    class="mr-2"
                    @click="separateChildParent"
                  >
                    <v-icon>mdi-book-remove-multiple-outline</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.ARCHIVE') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="error"
                    class="mr-2"
                    @click="removeItem"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.REMOVE') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                    depressed
                    color="pink"
                    class="mr-2"
                    @click="archiveItems(loaded_values && +loaded_values.status ? 0 : 1)"
                  >
                    <v-icon color="white">{{loaded_values && +loaded_values.status ? 'mdi-archive-arrow-down-outline' : 'mdi-archive-arrow-up-outline'}}</v-icon>
                  </v-btn>
                </template>
                {{ $t(loaded_values && +this.loaded_values.status ? 'COMMON.ARCHIVE' : 'COMMON.DISARCHIVE') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="mr-2"
                    @click="resetItem"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.RESET') }}
              </v-tooltip>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="teal darken-2"
                    class="mr-2"
                    @click="checkItem"
                  >
                    <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
                  </v-btn>
                </template>
                {{ $t('JOB.CHECK') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="info"
                    @click="refreshItem"
                  >
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                </template>
                {{ $t('JOB.REFRESH') }}
              </v-tooltip>

            </div>
          </div>
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                depressed
                color="success"
                @click="saveItem"
                :disabled="!valid"
              >
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.SAVE') }}
          </v-tooltip>
        </v-toolbar>
        <div class="toolbar-separator primary ml-4 mr-4"/>

        <v-dialog v-model="showSaveMultiple" width="350px" overlay-color="transparent">
          <v-card class="pa-4">
            <p class="ma-6 primary--text">
              {{$tc('COMMON.CONFIRM_MULTIPLE_ITEMS', this.ids.length)}}
            </p>
            <div class="d-flex">
              <v-btn rounded depressed large @click="showSaveMultiple = false">
                <v-icon class="mr-2">mdi-close</v-icon>{{$t('COMMON.CANCEL')}}
              </v-btn>
              <v-spacer />
              <v-btn color="success" rounded depressed large @click="saveItem(true)">
                <v-icon class="mr-2">mdi-check</v-icon>{{$t('COMMON.CONFIRM')}}
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

      </div>

      <div :class="['form-compact-content', blocked ? 'blocked' : '']">
        <v-overlay
          :value="blocked"
          v-if="loaded_values"
          color="white"
          :opacity="0.75"
          absolute
        >
          <div class="text-center" v-if="blocked">
            <v-icon :color="loaded_values.status_type" size="42">{{ $t(`${loaded_values.status_code}_ICON`) }}</v-icon>
            <p class="black--text">{{ $t(loaded_values.status_code) }}</p>
            <div class="mt-4" v-if="loaded_values.status_type === 'info'">
              <v-btn
                depressed
                small
                color="grey"
                @click="cancelRefresh"
              >
                {{ $t('COMMON.CANCEL') }}
              </v-btn>
            </div>
          </div>
        </v-overlay>
        <Loading :value="!!loading" />

        <div class="pa-4 pb-0">

          <!-- PARSING RESULT -->
          <template v-if="values && values.parsing_result">
            <v-alert
              text
              type="info"
              icon="mdi-sync"
              dense
            >
              <small>
                <p class="mb-0">Cette offre a été analysée.</p>
                <a @click="dialogParsing = true">Voir les résultats de l'analyse</a>
              </small>
            </v-alert>
          </template>

          <!-- STATUS_REPORT -->
          <template v-if="loaded_values && filterReportErrors(loaded_values.status_report)">
            <v-alert
              text
              type="error"
              icon="mdi-alert"
              dense
            >
              <small>
                <p class="mb-0">Des données sont manquantes pour publier sur tous vos canaux.</p>
                <a @click="showReport = true" v-if="!showReport">En savoir plus</a>
              </small>
              <transition>
                <div v-if="showReport" class="mt-2">
                  <template v-for="(controller_name,i) in Object.keys(loaded_values.status_report)">
                    <div :key="'err'+i" v-if="loaded_values.status_report[controller_name] !== true">
                      <v-row>
                        <v-col cols="9" v-if="loaded_values.status_report[controller_name] instanceof Array">
                          <div :key="'err'+j" v-for="(error, j) in loaded_values.status_report[controller_name]">
                            <v-chip x-small outlined color="error" class="mr-4">{{$t(error.code)}}</v-chip><small>{{error.field}}</small>
                          </div>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-center">
                          <v-img
                            :src="getImage(controller_name)"
                            height="15px"
                            max-width="100%"
                            contain
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                </div>
              </transition>
            </v-alert>
          </template>

          <!-- DUPLICATED -->
          <template v-if="loaded_values && loaded_values.parent_id">
            <v-alert
              text
              type="success"
              icon="mdi-content-duplicate"
              dense
            >
              <small>
                <p class="mb-0">{{ $t('JOB.HAS_PARENT') }}</p>
                <a target="blank" :href="`?edit=${this.loaded_values.parent_id}`">
                  {{ $t('JOB.HAS_PARENT_BTN') }}
                </a>
              </small>
            </v-alert>
          </template>

          <!-- ARCHIVED -->
          <template v-if="loaded_values && loaded_values.status === 0">
            <v-alert
              text
              color="pink"
              icon="mdi-archive-arrow-down-outline"
              dense
            >
              <small>{{ $t('JOB.ARCHIVED_TEXT') }}</small>
            </v-alert>
          </template>

          <!-- EVALUTAION -->
          <template v-if="loaded_values && loaded_values.parsing_result && Object.keys(loaded_values.parsing_result || {}).length > 1">
            <v-alert
              text
              color="info"
              icon="mdi-account-multiple-check"
              dense
            >
              <FormJobCompactEvaluation :loaded_values="loaded_values" />
            </v-alert>
          </template>

        </div>

        <v-container fluid class="pt-0 pb-10">
          <v-form v-model="valid">

            <div class="pr-6 pl-6 mt-4">
              <FormJobCompactIntro @input="handleInput" :values="values" />
            </div>

            <div class="pr-6 pl-6 mt-4">
              <FormJobCompactCompany @input="handleInput" :values="values" />
            </div>

            <div class="pr-6 pl-6 mt-10">
              <FormJobCompactAdvanced @input="handleInput" :values="values" />
            </div>

            <div class="pr-6 pl-6 mt-10">
              <FormJobCompactCustom @input="handleInput" :values="values" />
            </div>

          </v-form>
        </v-container>
      </div>

    </div>

</template>

<script>

import Loading from '@/components/Loading'
import Tree from '@/components/Tree'
import StatusLabel from '@/components/StatusLabel'

import FormJobMixins from '../FormJobMixins'

import FormJobCompactIntro from './FormJobCompact.intro'
import FormJobCompactCompany from './FormJobCompact.company'
import FormJobCompactAdvanced from './FormJobCompact.advanced'
import FormJobCompactCustom from './FormJobCompact.custom'
import FormJobCompactEvaluation from './FormJobCompact.evaluation'

export default {
  name:"FormJobCompact",
  mixins: [FormJobMixins],
  components: {
    Loading,
    Tree,
    StatusLabel,
    FormJobCompactIntro,
    FormJobCompactCompany,
    FormJobCompactAdvanced,
    FormJobCompactCustom,
    FormJobCompactEvaluation
  },
  data: () => ({
    valid: false,
    showReport: false,
    dialogParsing: false,
  }),
  computed: {
    blocked () {
      return this.loaded_values ? ['info', 'warning'].indexOf(this.loaded_values.status_type) !== -1 : false
    }
  },
  watch: {
    blocked (n) {
      if (!n) this.stopInterval()
      else this.startInterval()
    }
  },
  beforeDestroy () {
    this.stopInterval()
  },
  methods: {

    filterReportErrors (status_report) {
      if (status_report && Object.keys(status_report).length > 0) {
        const keys = Object.keys(status_report)
        for (let i = 0; i < keys.length; i++) {
          const error = status_report[keys[i]]
          if (error !== true) return true
        }
      }
      return false
    },

    getImage(controller_name, size = 'original') {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + controller_name.toLowerCase()}.png`
    },
    startInterval () {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.loadItem()
      }, 5000)
    },
    stopInterval () {
      clearInterval(this.interval)
      this.$emit('update')
    }
  }
}
</script>

<style>

.description-html-container {
  margin-bottom: 20px;
}

.description-html {
  height: 290px;
}

.profile-html-container {
  margin-bottom: 20px;
}
.profile-html {
  height: 140px;
}

.html-content p {
  margin-bottom: 10px;
}
.html-content h1,
.html-content h2,
.html-content h3,
.html-content h4,
.html-content h5,
.html-content h6,
.html-content h1,
.html-content h2,
.html-content h3,
.html-content h4,
.html-content h5,
.html-content h6
{
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
