<template>
  <div class="tree height-100 scroll">
    <div class="tree-item" v-for="(item, i) in results" :key="i">
      <div class="tree-item-key">{{item.key}}</div>
      <div class="tree-item-value">{{item.data}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tree',
    props: ['value'],
    data: () => ({
      results: []
    }),
    mounted () {
      this.handleValue()
    },
    methods: {
      handleValue () {
        if (this.value) {
          // example
          const value = this.value
          const results = []
          const recurse = (data, prevKeys = []) => {
            if (typeof data !== 'undefined') {
              if (data instanceof Array) {
                for (let i = 0, len = data.length; i < len; i++) {
                  recurse(data[i], [...prevKeys].concat([i]))
                }
              } else if (data instanceof Object) {
                for (const key of Object.keys(data)) {
                  recurse(data[key], [...prevKeys].concat([key]))
                }
              } else if (data || data === 0) {
                results.push({data, key: prevKeys.join('.')})
              }
            }
          }
          recurse(value)
          this.results = results
        }
      }
    }
  }
</script>
<style>

  .tree { font-size: 12px; background: #222; }
  .tree-item { margin-bottom: 10px;}
  .tree-item-key { border-top: solid 1px rgba(255,255,255,.1); color:orange;  padding: 5px 20px; margin-bottom: 5px; font-size: 11px; }
  .tree-item-value { padding:5px 20px; color: #fff; white-space:wrap; font-size:12px; font-family: Mono; white-space: pre-wrap; font-weight: bold; }

</style>
