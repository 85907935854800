<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
      :eager="true"
    >
      <v-form ref="form_protocol" v-model="valid">
        <v-card max-height="100vh">

          <div>
            <v-system-bar dark color="primary">
              <v-icon class="mr-1">mdi-pencil</v-icon>
              <small><strong class="text-uppercase">{{ $t('COMMON.MODIFY') }}</strong></small>
              <v-spacer/>
              <v-icon
                small
                class="clickable"
                @click="closeDialog"
              >
                mdi-close
              </v-icon>
            </v-system-bar>

            <v-toolbar
              color="transparent"
              flat
              dense
              height="60px"
            >
              <v-toolbar-title>{{ $t('JOB.RECRUITER') }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                x-small
                fab
                depressed
                color="error"
                class="mr-4"
                @click="closeDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                rounded
                depressed
                color="success"
                :disabled="!valid"
                @click="apply"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                {{ $t('COMMON.APPLY') }}
              </v-btn>
            </v-toolbar>

            <div class="toolbar-separator primary ml-4 mr-4"/>
          </div>

          <div class="pa-6 pt-0 scroll" style="max-height: calc(80vh - 61px)">

            <p class="primary--text mt-6">{{ $t('JOB.COMPANY') }}</p>
            <v-row class="mt-0">
              <v-col cols="8" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="company_title"
                  @change="$emit('input', {company_title: $event})"
                  :rule="[$rules.maxlength(255)]"
                  :label="$t('JOB.FIELD_COMPANY_TITLE')"
                  :placeholder="$t('JOB.FIELD_COMPANY_TITLE_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-select
                  v-model="xtramile_sector_id"
                  :items="sectorItems"
                  item-value="value"
                  item-text="text"
                  @change="$emit('input', {xtramile_sector_id: $event})"
                  :label="$t('JOB.FIELD_SECTOR')"
                  :placeholder="$t('JOB.FIELD_SECTOR_PLACEHOLDER')"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-0">
              <v-col cols="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  v-model="company_description"
                  @change="$emit('input', {company_description: $event})"
                  rows="3"
                  :rule="[$rules.maxlength(255)]"
                  :label="$t('JOB.FIELD_COMPANY_DESCRIPTION')"
                  :placeholder="$t('JOB.FIELD_COMPANY_DESCRIPTION_PLACEHOLDER')"
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="pb-0">
                <v-text-field
                  dense
                  v-model="company_url"
                  @change="$emit('input', {company_url: $event})"
                  :rule="[$rules.url]"
                  :label="$t('JOB.FIELD_COMPANY_URL')"
                  :placeholder="$t('JOB.FIELD_COMPANY_URL_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                dense
                v-model="company_image_url"
                @change="$emit('input', {company_image_url: $event})"
                :rule="[$rules.url]"
                :label="$t('JOB.FIELD_COMPANY_IMAGE_URL')"
                :placeholder="$t('JOB.FIELD_COMPANY_IMAGE_URL_PLACEHOLDER')"
                outlined
                />
              </v-col>
            </v-row>


            <p class="primary--text">{{ $t('JOB.CONTACT') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="12" class="pt-0">
                <v-text-field
                  dense
                  v-model="contact_name"
                  @change="$emit('input', {contact_name: $event})"
                  :rule="[$rules.maxlength(255)]"
                  :label="$t('JOB.FIELD_CONTACT_NAME')"
                  :placeholder="$t('JOB.FIELD_CONTACT_NAME_PLACEHOLDER')"
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-0">
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  :rule="[$rules.email]"
                  v-model="contact_email"
                  @change="$emit('input', {contact_email: $event})"
                  :label="$t('JOB.FIELD_CONTACT_EMAIL')"
                  :placeholder="$t('JOB.FIELD_CONTACT_EMAIL_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>

          </div>
        </v-card>
      </v-form>
    </v-dialog>

    <div>
      <span class="primary--text">{{ $t('JOB.RECRUITER') }}</span>
      <v-btn
        small
        rounded
        depressed
        color="secondary black--text"
        class="ml-4 mr-4"
        @click="showDialog"
      >
        {{ $t('COMMON.MODIFY') }}
      </v-btn>
      <!-- <span class="grey--text text-medium">
        {{ $tc('JOB.PROFILE_VALIDATION', values.profile.length) }}
      </span> -->
      <v-text-field
        name="form_description"
        style="display:none;"
        :value="valid"
        :rules="[$rules.required]"
      />
    </div>

    <div class="mt-4">

      <template v-if="values.company_title">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.COMPANY_TITLE')">
            {{ $t('JOB.COMPANY_TITLE') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.company_title }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.xtramile_sector_id">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.FIELD_SECTOR')">
            {{ $t('JOB.FIELD_SECTOR') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ $t('JOB.SECTOR.' + values.xtramile_sector_id) }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.company_description">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.FIELD_COMPANY_DESCRIPTION')">
            {{ $t('JOB.FIELD_COMPANY_DESCRIPTION') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.company_description }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.company_url">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.COMPANY_URL')">
            {{ $t('JOB.COMPANY_URL') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.company_url }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.company_image_url">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.COMPANY_IMAGE_URL')">
            {{ $t('JOB.COMPANY_IMAGE_URL') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.company_image_url }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.contact_name">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.CONTACT_NAME')">
            {{ $t('JOB.CONTACT_NAME') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.contact_name }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.contact_email">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.CONTACT_EMAIL')">
            {{ $t('JOB.CONTACT_EMAIL') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.contact_email }}</span>
          </v-col>
        </v-row>
      </template>

    </div>
  </div>
</template>

<script>

export default {
  name:"FormJobCompactDescription",
  components: {},
  data: (_this) => ({
    valid: false,
    dialog: false,
    sectorItems: _this.$i18n.t('JOB.XTRAMILE_SECTORS_ITEMS').sort((a, b) => a.text.localeCompare(b.text)),
    //
    company_title: undefined,
    company_description: undefined,
    company_url: undefined,
    company_image_url: undefined,
    contact_name: undefined,
    contact_email: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  computed: {
    filledFieldsCount () {
      return +!!this.protocol
    },
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.attribValues()
  },
  methods: {

    // form fields
    apply () {
      if (this.$refs.form_protocol.validate()) {
        this.$emit('input', {
          company_title: this.company_title,
          company_description: this.company_description,
          company_url: this.company_url,
          company_image_url: this.company_image_url,
          contact_name: this.contact_name,
          contact_email: this.contact_email,
        })
        this.dialog = false
      }
    },
    closeDialog () {
      this.dialog = false
    },
    showDialog () {
      this.fileformat_fields_form = this.$clone(this.fileformat_fields)
      this.dialog = true
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
